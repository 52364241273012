import Swiper from 'swiper'; import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/pagination";

//パラメータは以下参照
//https://swiperjs.com/swiper-api#parameters

export function slider(sliderEl) {
  if ($(sliderEl).length) {
    const swiper = new Swiper(sliderEl, {
      direction: "horizontal",
      loop: true,
      autoHeight: false,
      centeredSlides: false,
      effect: "slide",
      modules: [Navigation, Autoplay, Pagination],
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: "auto",
      spaceBetween: 10,
      // Responsive breakpoints
      breakpoints: {
        320: {
          spaceBetween: 20
        },
        // when window width is >= 640px
        480: {
          spaceBetween: 20
        },
        768: {
          spaceBetween: 40
        },
      },
      autoplay: {
        delay: 5000,
      },
    });
  }
}
