export function slideIn(slideInEl) {
  // const slideConts = document.querySelectorAll($(slideInEl));
  const slideContsRect = []; // 要素の位置を入れるための配列
  const slideContsTop = []; // 要素の位置を入れるための配列
  let windowY = window.pageYOffset; // ウィンドウのスクロール位置を取得
  let windowH = window.innerHeight; // ウィンドウの高さを取得
  const remainder = 250; // ちょっとはみ出させる部分
  // 要素の位置を取得
  for (let i = 0; i < $(slideInEl).length; i++) {
    slideContsRect.push($(slideInEl)[i].getBoundingClientRect());
  }
  for (let i = 0; i < slideContsRect.length; i++) {
    slideContsTop.push(slideContsRect[i].top + windowY);
  }
  // ウィンドウがリサイズされたら、ウィンドウの高さを再取得
  window.addEventListener('resize', function () {
    windowH = window.innerHeight;
  });
  // スクロールされたら
  window.addEventListener('scroll', function () {
    // スクロール位置を取得
    windowY = window.pageYOffset;

    for (let i = 0; i < $(slideInEl).length; i++) {
      // 要素が画面の下端にかかったら
      if(windowY > slideContsTop[i] - windowH + remainder) {
        // .is-showを付与
        $(slideInEl)[i].classList.add('is-show');
      } else {
        // // 逆に.is-showを削除
        // slideConts[i].classList.remove('is-show');
      }
    }
  });
}
