export function inputError(formEl, addErrorClass, errorEl) {
  if ($(formEl).length) {
    if ($(formEl).find(`.${errorEl}`)) {
      $(formEl).find(`.${errorEl}`).addClass(addErrorClass);
    }
    const $inputEl = $(formEl).find('input[type="text"]');
    $inputEl.each(function () {
      const $formEl = document.querySelector<HTMLElement>("form");
      const $errorEl = $formEl ? $(this).siblings(`.${addErrorClass}`) : null;
      if ($errorEl) {
        $(this).on("input", function () {
          $(this).siblings(`.${addErrorClass}`).removeClass(addErrorClass);
          console.log("input");
          
        });
      }
    });
  }
}
