export function scrollAnime(animeEl, addAnimeClass) {
  if ($(animeEl).length) {
    $(animeEl).each(function () {
      let scroll = $(window).scrollTop();
      let windowHeight = $(window).height();
      let elmHeight = $(this).offset()?.top;
      if (scroll && windowHeight && elmHeight) {
        let elemPos = elmHeight + windowHeight / 2;
        if (scroll >= elemPos - windowHeight) {
          $(this).addClass(addAnimeClass);
        }
      }
    });
  }
}
