export function accordion(
  accordionEl,
  accordionButtonEl,
  accordionItemEl
) {
  if (
    $(accordionEl).length &&
    $(accordionButtonEl).length &&
    $(accordionItemEl).length
  ) {
    $(accordionEl)
      .children(accordionButtonEl)
      .on("click", function () {
        $(this).toggleClass("active");
        $(this).children(accordionItemEl).slideToggle();
      });
  }
}
