import { scrollAnime } from "./components/scrollAnime";
// import { humberger } from "./components/humberger";
import { accordion } from "./components/accordion";
import { tab } from "./components/tab";
import { slideIn } from "./components/slidein";
import { slider } from "./components/slider";
import { inputError } from "./components/inputError";
import { megaMenu } from "./components/megaMenu";
import { pageTop } from "./components/pageTop";



$(document).ready(function () {

  (() => {
    /*
    アニメーション用関数
    */
    //アニメーション用クラスを定義
    const animeEl = ".u-anime";
    //スクロール時に付与されるクラス定義
    const addAnimeClass = "fadeIn";
    scrollAnime(animeEl, addAnimeClass);

  })();

  // (() => {
  //   /*
  //   ハンバーガーメニュー用関数
  //   */
  //   //ハンバーガーメニューボタンクラス定義
  //   const buttonEl = ".l-header__toggle";
  //   //ハンバーガーメニュークラス定義
  //   const menuEl = ".l-header__navsp";
  //   humberger(buttonEl, menuEl);
  // })();

  (() => {
    /*
    スライダー用関数
    */
    const sliderEl = ".swiper";
    slider(sliderEl);
  })();

  (() => {
    /*
    スライダー用関数
    */
    const slideInEl = ".u-animation--slidein";
    slideIn(slideInEl);
  })();

  (() => {
    /*
    アコーディオン用関数
    */
    //アコーディオンラッパークラス定義
    const accordionEl = ".accordion";
    //アコーディオンボタンクラス定義
    const accordionButtonEl = ".accordionButton";
    //アコーディオンアイテムクラス定義
    const accordionItemEl = ".accordionItem";
    accordion(accordionEl, accordionButtonEl, accordionItemEl);
  })();

  (() => {
    /*
    タブ用関数
    */
    //タブボタンラッパークラス定義
    const tabButtonEl = ".tabButton";
    //タブコンテンツラッパークラス定義
    const tabContentsEl = ".tabContent";
    tab(tabButtonEl, tabContentsEl);
  })();

  (() => {
    /*
    メガメニュー用関数
    */
    //メガメニューラッパークラス定義
    const megaMenuEl = "#megaMenu";
    //メガメニューナビアイテムクラス定義
    const megaMenuItemEl = ".megaMenuItem";
    //メガメニューコンテンツクラス定義
    const megaMenuContents = ".megaMenuContents";
    megaMenu(megaMenuEl, megaMenuItemEl, megaMenuContents);
  })();

  (() => {
    /*
    フォームバリデーション用関数
    */
    //フォームラッパークラス定義
    const formEl = ".p-form";
    //エラー表示用クラス定義
    const addErrorClass = "c-error";
    //WPなどでフォームから出力されるエラークラスを定義
    const errorEl = "error";
    inputError(formEl, addErrorClass, errorEl);
  })();

  (() => {
    /*
    ページトップボタン用関数
    */
    //ボタンクラス定義
    const pageTopButtonEl = "#pageTop";
    //初期状態を定義
    const hide = true;
    pageTop(pageTopButtonEl, hide);
  })();
});


const buttonEl = ".l-header__toggle";
const menuEl = ".l-header__navsp";
if ($(buttonEl).length) {
  $(menuEl).hide();
  $(buttonEl).on('click', function () {
  // $(document).on('click', buttonEl, function(){
    if ($(this).hasClass('is-active')) {
      $(this).removeClass('is-active');
      $(menuEl).slideUp();
    } else {
      $(this).addClass('is-active');
      $(menuEl).slideDown();
    }
  });
  $(menuEl + ' a').on('click', function () {
  // $(document).on('click', menuEl + ' a', function(){
    // console.log('test');
    $(buttonEl).removeClass('is-active');
    $(menuEl).slideUp();
  });
}
$(document).ready(function () {
  var headerHight = 100; //ヘッダーの高さ
  $('a[href^="#"]').click(function () {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    if(target.length) {
      var position = target.offset().top - headerHight;
      $("html, body").animate({
        scrollTop: position
      }, 300, "swing");
    }
    return false;
  });
});
